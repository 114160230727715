import React, { useEffect, useState } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";
import { Link } from "react-router-dom";

const Referralhis = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const userResponse = await axios.get(`${baseUrl}me`, { headers });
      setUser(userResponse.data);

      await fetchReferralData(userResponse.data.referral_code);
    } catch (err) {
      console.error("Error fetching user details:", err);
      setError("Failed to load data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fetchReferralData = async (referralCode) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const referralResponse = await axios.get(
        `${baseUrl}referral/code/winn/${referralCode}`,
        { headers }
      );

      setCardData(referralResponse.data || []);
    } catch (err) {
      console.error("Error fetching referral data:", err);
      setError("Failed to load referral history.");
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div>
      {/* Navigation Buttons (Always Visible) */}
             <div className="pt-5 mb-3"></div>
     
      <div
        className="card mt-2 p-2"
        style={{
          backgroundColor: "#333333",
          borderRadius: "1px",
          padding: "10px",
          margin: "0 auto",
        }}
      >
        <div className="d-flex justify-content-between">
          <Link to="/Gamehistory" className={css.Link}>
            <span className="font-9" style={{ fontWeight: "500", color: "black" }}>
              Game
            </span>
          </Link>

          <Link to="/transaction-history" className={css.Link}>
            <span className="font-9" style={{ fontWeight: "500", color: "black" }}>
              Wallet
            </span>
          </Link>

          <Link to="/Referral-history" className={css.Linked}>
            <span className="font-9" style={{ fontWeight: "500", color: "white" }}>
              Referral
            </span>
          </Link>
        </div>
      </div>

      {/* Content Area */}
      <div className="leftContainer" style={{ minHeight: "100vh" }}>
        {/* Spinner while loading referral data */}
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "80vh",
              backgroundColor: "#f9f9f9",
            }}
          >
            <div
  className="spinner-border"
  role="status"
  style={{
    width: "3rem",
    height: "3rem",
    borderWidth: "0.4rem", // Border ko bold karne ke liye
    borderColor: "blue", // Border ko blue color dene ke liye
    borderTopColor: "transparent", // Spinner ka effect maintain karne ke liye
  }}
>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : error ? (
          // Error Message
          <div
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h3 className="text-danger">{error}</h3>
            <p className="text-muted">Please try refreshing the page.</p>
          </div>
        ) : cardData.length > 0 ? (
          // Referral History Cards
          cardData.map((card) => (
            <div
              className={`w-100 py-2 d-flex align-items-center ${css.list_item}`}
              key={card._id}
              style={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                border: "1px solid #ddd",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                color: "#333",
                marginBottom: "10px",
              }}
            >
              <div className={`mx-3 d-flex ${css.list_body}`}>
                <div className="d-flex align-items-center">
                  <picture className="mr-2">
                    <img
                      height="32px"
                      width="32px"
                      src="https://Ludopay.in/ludo.jpeg"
                      alt=""
                      style={{ borderRadius: "5px" }}
                    />
                  </picture>
                </div>
                <div className="d-flex flex-column font-8">
                  <div style={{ fontSize: "0.85rem", fontWeight: "bold" }}>
                    Referral Earning
                  </div>
                  <div
                    className={`${css.games_section_headline}`}
                    style={{ fontSize: "0.75rem", color: "#555" }}
                  >
                    Earned by: <b>{card.earned_from.Name}</b>
                  </div>
                  <div style={{ fontSize: "0.75rem", color: "#555", marginTop: "4px" }}>
                    {dateFormat(card.createdAt)}
                  </div>
                </div>
              </div>

              <div
                className="right-0 d-flex align-items-end pr-3 flex-column"
                style={{ marginLeft: "auto" }}
              >
                <div
                  className="d-flex float-right font-8"
                  style={{ fontSize: "0.85rem" }}
                >
                  <picture className="ml-1 mb-1">
                    <img
                      height="18px"
                      width="18px"
                      src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
                      alt=""
                    />
                  </picture>
                  <span className="pl-1">
                    <b>{card.amount.toFixed(1)}</b>
                  </span>
                </div>
                <div
                  className="games-section-headline"
                  style={{
                    fontSize: "0.6rem",
                    whiteSpace: "nowrap",
                    color: "#777",
                  }}
                >
                  Closing Balance: {card.closing_balance.toFixed(1)}
                </div>
              </div>
            </div>
          ))
        ) : (
          // No Data Fallback
          <div
            className="text-center"
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
              color: "#333",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/Images/notransactionhistory.png"}
              alt="no data"
              width={250}
              height={250}
              className="img-fluid"
              style={{ marginTop: "20%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold" style={{ color: "#333" }}>
                No Referral History
              </h3>
              <p className="text-muted">You have not made any referrals yet.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Referralhis;