import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Homepage from "./uiComponents/Homepage";
import Landing from "./uiComponents/Landing";
import userLogin from "./uiComponents/Login";
import Mywallet from "./uiComponents/Mywallet";
import Addcase from "./uiComponents/Addcase";
import Withdrawopt from "./uiComponents/Withdrawopt";
import Profile1 from "./uiComponents/Profile1";
import ViewGame1 from "./uiComponents/ViewGame1";
import Gamehistory from "./uiComponents/Gamehistory";
import Transactionhistory from "./uiComponents/Transactionhistory";
import Referralhis from "./uiComponents/Referralhis";
import Refer from "./uiComponents/Refer";
import Notification from "./uiComponents/Notification";
import Support from "./uiComponents/Support";
import Kyc2 from "./uiComponents/Kyc2";
import RefundPolicy from "./uiComponents/RefundPolicy";
import terms_condition from "./uiComponents/terms_condition";
import PrivacyPolicy from "./uiComponents/PrivacyPolicy";
import Gamerules from "./uiComponents/Gamerules";
import ResponsibleGaming from "./uiComponents/ResponsibleGaming";
import Return from "./uiComponents/Return";
import Notify from "./uiComponents/Notify";
import Redeem from "./uiComponents/Redeem";
import AboutUs from "./uiComponents/AboutUs";
import leaderboard from "./uiComponents/leaderboard";
import Header from "./Components/Header";
import Rightcontainer from "./Components/Rightcontainer";
import axios from "axios";
import "animate.css";

const App2 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();

  const getUser = () => {
    const headers = { Authorization: `Bearer ${access_token}` };
    axios
      .get(baseUrl + "me", { headers })
      .then((res) => setUser(res.data))
      .catch((e) => {
        if (e.response?.status === 401) localStorage.removeItem("token");
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div className="leftContainer">
        <Header user={user} />
      </div>
      {!access_token ? (
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/landing" component={Landing} />
          <Route path="/login" component={userLogin} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/term-condition" component={terms_condition} />
          <Route path="/about" component={AboutUs} />
          <Route path="/contact-us" component={Support} />
          <Route path="/Gamerules" component={Gamerules} />
          <Route path="/responsible-gaming" component={ResponsibleGaming} />
          <Redirect to="/login" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/transaction-history" component={Transactionhistory} />
          <Route path="/Referral-history" component={Referralhis} />
          <Route path="/landing" component={Landing} />
          <Route path="/Gamehistory" component={Gamehistory} />
          <Route path="/HomePage/:Game" render={() => <Homepage walletUpdate={getUser} />} />
          <Route path="/refer" component={Refer} />
          <Route path="/leaderboard" component={leaderboard} />
          <Route path="/Notification" component={Notification} />
          <Route exact path="/" component={Landing} />
          <Route path="/profile" component={Profile1} />
          <Route path="/viewgame1/:id" component={ViewGame1} />
          <Route path="/addcase" render={() => <Addcase walletUpdate={getUser} />} />
          <Route path="/Withdrawopt" render={() => <Withdrawopt walletUpdate={getUser} />} />
          <Route path="/wallet" component={Mywallet} />
          <Route path="/support" component={Support} />
          <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
          <Route path="/Rules" component={Gamerules} />
          <Route path="/return" render={() => <Return walletUpdate={getUser} />} />
          <Route path="/notify" component={Notify} />
          <Route path="/Redeem" render={() => <Redeem walletUpdate={getUser} />} />
          <Redirect to="/landing" />
        </Switch>
      )}
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
};

export default App2;