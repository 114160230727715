import React from "react";
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";
import avtar1 from "../Avtar/Avatar1.png";
import avtar2 from "../Avtar/Avatar2.png";
import avtar3 from "../Avtar/Avatar3.png";
import avtar4 from "../Avtar/Avatar4.png";
import avtar5 from "../Avtar/Avatar5.png";
import avtar6 from "../Avtar/Avatar6.png";

export default function RunningCard({ runnig, user, winnAmount, game_type }) {
  const avatars = [avtar1, avtar2, avtar3, avtar4, avtar5, avtar6];
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  function getRandomAvatar() {
    const randomIndex = Math.floor(Math.random() * avatars.length);
    return avatars[randomIndex];
  }

  const randomAvatar = getRandomAvatar();

  return (
    <div className={`${css.betCard} mt-1`}>
      <div className="d-flex">
        <span
          className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase`}
        >
          PLAYING FOR
          <img
            className="mx-1"
            src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
            alt=""
            width="18px"
          />
          {runnig.Game_Ammount}
        </span>

        {(user === runnig.Accepetd_By._id || user === runnig.Created_by._id) && (
          <Link
            className={`${css.bgsecondary} ${css.playButtons} ${css.cxy} position-relative m-2 mx-1 ${
              runnig.Status === "conflict" ? "bg-danger" : "bg-success"
            }`}
            style={{
              right: "0px",
              top: "-6px",
              padding: "10px 17px",
            }}
            to={{
              pathname: `/viewgame1/${runnig._id}`,
              state: { prevPath: window.location.pathname },
            }}
          >
            {runnig.Status === "conflict" ? "Review" : "View"}
          </Link>
        )}
        <div
          className={`${css.betCardTitle} d-flex align-items-center text-uppercase`}
        >
          <span className="ml-auto mr-3">
            PRIZE
            <img
              className="mx-1"
              src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
              alt=""
              width="18px"
            />
            {runnig.Game_Ammount + winnAmount(runnig.Game_Ammount)}
          </span>
        </div>
      </div>
      <div className="py-1 row">
        <div className="pr-3 text-center col-5">
          <div className="pl-2">
            {runnig.Created_by.avatar ? (
              <img
                src={baseUrl + `${runnig.Created_by && runnig.Created_by.avatar}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = getRandomAvatar();
                }}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                src={getRandomAvatar()}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
          <div style={{ lineHeight: 1 }}>
            <span className={css.betCard_playerName}>
              {runnig.Created_by.Name}
            </span>
          </div>
        </div>
        <div className="pr-3 text-center col-2 cxy">
          <div>
            <img
              src="https://i.postimg.cc/kG2gYsfM/vs.png"
              alt=""
              width="21px"
            />
          </div>
        </div>
        <div className="text-center col-5">
          <div className="pl-2">
            {runnig?.Accepetd_By.avatar ? (
              <img
                src={baseUrl + `${runnig.Accepetd_By && runnig.Accepetd_By.avatar}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = getRandomAvatar();
                }}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                src={getRandomAvatar()}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
          <div style={{ lineHeight: 1 }}>
            <span className={css.betCard_playerName}>
              {runnig.Accepetd_By.Name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}