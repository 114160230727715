import React, { memo } from 'react'
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";
import acceptSound from "./accept.mp3";
import findGif from "../css/loading_old.gif";
import playSound from "./play.mp3";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}

const BetCard = React.memo(({ allgame, user, deleteChallenge, getPost, RejectGame, winnAmount, AcceptChallang, updateChallenge }) => {
  
  return (<div className={`${css.betCard} mt-2`}>
    <span
      className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase ${css.betTitleDiv}`}
    >
      CHALLENGE FROM
      <span className="ml-1" style={{ color: "red" }}>
        {allgame.Created_by.Name}
      </span>
      {user == allgame.Created_by._id &&
        allgame.Status == "new" && (
        <button
  className={`text-white position-relative mx-1 ml-auto`}
  onClick={() => deleteChallenge(allgame._id)}
  style={{ 
    backgroundColor: '#E90042', 
    padding: '0.3rem 0.9rem', 
    fontSize: '1rem', 
    borderRadius: '4px',
    borderStyle: 'solid',
    borderColor: '#E90042'
    
  }}
>
  DELETE 
</button>

        )}
      {user == allgame.Created_by._id &&
        allgame.Status == "requested" && (<div className="d-flex ml-auto align-items-center">

                  <Link to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}  onClick={(e) => getPost(allgame._id)} style={{ bottom: '0' }}>
        <button
  className={`text-white position-relative mx-1 ml-auto`}
  style={{ 
    backgroundColor: 'green', 
    padding: '0.3rem 0.9rem', 
    fontSize: '1rem', 
    borderRadius: '4px',
    borderStyle: 'solid',
    borderColor: 'green'
  }}
>
  START
</button>
          </Link>
          <button
  className={`text-white position-relative mx-1 ml-auto`}
  onClick={() => RejectGame(allgame._id)}
  style={{ 
    backgroundColor: '#E90042', 
    padding: '0.3rem 0.9rem', 
    fontSize: '1rem', 
    borderRadius: '4px',
    borderStyle: 'solid',
    borderColor: '#E90042'
  }}
>
  REJECT
</button>
        </div>
        )}
    </span>
    <div className={`d-flex pl-3 ${css.betBodyDiv}`}>
      <div className="pr-3 pb-1">
        <span className={css.betCardSubTitle}>Entry Fee</span>
        <div>
          <img
            src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
            alt=""
            width="21px"
          />
         <span className={css.betCardAmount}>
            {allgame.Game_Ammount}
          </span>
        </div>
      </div>
      <div>
        <span className={css.betCardSubTitle}>Prize</span>
        <div>
          <img
            src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
            alt=""
            width="21px"
          />
          <span className={css.betCardAmount}>
            {allgame.Game_Ammount +
              winnAmount(allgame.Game_Ammount)}
          </span>
        </div>
      </div>
      {user !== allgame.Created_by._id &&
        allgame.Status == "new" && (
          <button
            className={`${css.bgSecondary} ${css.playButton} ${css.cxy}`}
            onClick={() => AcceptChallang(allgame._id)}
          >
            Play
          </button>
          )}
    
      {user == allgame.Created_by._id &&
        allgame.Status == "new" && (
          <div className="text-center col-5 ml-auto mt-auto mb-auto">
            <div className="pl-2 text-center">
                <div className="spinner-border text-dark w-80" role="status" style={{ width: '1rem', height: '1rem', borderWidth: '0.20em' }}>
      <span className="sr-only">Loading...</span>
    </div>
            </div>
            <div style={{ lineHeight: 1 }}>
              <span className={css.betCard_playerName}>
                Finding Player!
              </span>
            </div>
          </div>
        )}
      {user !== allgame.Created_by._id &&
        allgame.Status == "requested" && (
          <div className="d-flex mt-3 ml-auto align-items-center">
        <button
  className={`${css.bg} ${css.playButton} ${css.cxy} position-relative mx-1 text-white btn-sm`}
  style={{ 
    backgroundColor: '#555', // Green color for a fresh look
    color: '#fff', 
    padding: '10px 20px', 
    fontSize: '1.1rem', 
    fontWeight: 'bold', // Making text bold
    borderRadius: '4px', // Softer rounded corners
    border: 'none', // Remove border
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow for depth
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease' // Smooth hover effect
  }}
  onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'} // Slightly darker green on hover
  onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'} // Original color on mouse out
>
  <i 
    className="fa fa-circle-o-notch fa-spin" 
    style={{ 
      fontWeight: 'bold', // Bold spinner
      marginRight: '8px' // Add space after spinner
    }}
  ></i>
  Requesting
</button>

            <button
  className={`${css.bg} ${css.playButton} ${css.cxy} position-relative mx-1 btn-sm`}
  onClick={() => RejectGame(allgame._id)}
  style={{ 
    backgroundColor: '#E90042',
    padding: '0.5rem 1rem', 
    fontSize: '1rem', 
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: '#E90042'
  }}
>
  Cancel
</button>
          </div>
        )}
      {user !== allgame.Created_by._id &&
        allgame.Status == "running" && (
         <div className="d-flex ml-auto align-items-center">
    <audio src={playSound} autoPlay onEnded={(e) => e.target.play()}>
    </audio>
    <Link
        className={`${css.bgSecondary} ${css.playButton} ${css.cxy} bg-success btn-sm'`}
        to={{ pathname: `/viewgame1/${allgame._id}`, state: { prevPath: window.location.pathname } }}
        onClick={(e) => updateChallenge(allgame._id)}
    >
        start
    </Link>
</div>
          
        )}
      {user == allgame.Created_by._id &&
        allgame.Status == "requested" && (
          <div className="d-flex ml-auto align-items-center mr-5 mt-1">
           <audio src={acceptSound} autoPlay onEnded={(e) => e.target.play()}>
</audio>
            <div className="text-center col">
              <div className="pl-2">
                {allgame.Accepetd_By.avatar ? (<img
                  src={baseUrl+`${allgame.Accepetd_By.avatar}`}
                  alt=""
                  width='40px' height="40px"
                  style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%", marginTop: "5px" }}
                />) : (<img
                  src={`https://Ludopay.in/user.png`}
                  alt=""
                  width='30px' height="30px"
                  style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%", marginTop: "5px" }}
                />)}
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className={css.betCard_playerName}>
                  {allgame.Accepetd_By.Name}
                </span>
              </div>
            </div>
          </div>
        )}
    </div>
  </div>)
})

export default memo(BetCard);
