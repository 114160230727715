import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import styles from "../css/notification.module.css";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
  const [open, setOpen] = useState(false);
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(baseUrl + "settings/data");
      setWebsiteSettings(response.data);
    } catch (error) {
      if (!navigator.onLine) {
        toast.error("Please check your internet connection.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const [kycStatus, setKycStatus] = useState();
  const [showCanvas, setShowCanvas] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleShowCanvas = () => {
    setShowCanvas(true);
    setIsButtonClicked(true);
  };

  const handleCloseCanvas = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowCanvas(false);
      setIsClosing(false);
    }, 300);
  };

  const checkRole = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const res = await axios.get(baseUrl + `me`, { headers });
      setKycStatus(res.data);
      const userRole = res.data.role;
      if (userRole === 'admin') {
        console.log('User is an admin');
      } else {
        console.log('User is not an admin');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      } else {
        console.error("Error checking role:", error);
      }
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const updateVisibility = () => {
      const currentHour = new Date().getHours();
      setIsVisible(currentHour % 2 !== 0);
    };

    updateVisibility();
    const intervalId = setInterval(updateVisibility, 90000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchData();
    checkRole();
  }, []);

  return (
    <>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "60px" }}>
               {kycStatus && kycStatus.verified === "unverified" && (
            <div className="d-flex align-items-center justify-content-between alert alert-light show mt-2" style={{ width: '94%', margin: '0 auto', border: '1px solid #ddd', borderRadius: '5px' }}>
              <span>
                <b>KYC Pending</b> &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20" fill="red">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                </svg>
              </span>
              <Link to="/Kyc2" style={{ textDecoration: 'none' }}>
                <button className="btn btn-danger btn-sm text-capitalize" style={{ color: 'white', border: 'none' }}>
                  <span style={{ color: '#fff', fontWeight: 'bold' }}>
                    <b>Complete here</b>
                  </span>
                </button>
              </Link>
            </div>
          )}
{WebSitesettings && WebSitesettings.CompanyAddress && (
  <div className="mt-2 container position-relative" style={{ maxWidth: '100%' }}>
    <div
      role="alert"
      className="fade d-flex align-items-center justify-content-between alert alert-dark show text-start"
      style={{
        fontSize: '0.8rem',  // Smaller font size
        background: 'linear-gradient(145deg, #6a11cb, #2575fc)',  // Keeping the original gradient
        borderRadius: '4px',  // Reduced border-radius
        border: '2px solid #2575fc',  // Keeping the original border color
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',  // Slightly smaller box-shadow
        padding: '8px 16px',  // Reduced padding for smaller alert
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.05)';
        e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
      }}
    >
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="#fff">  {/* Keeping original icon color */}
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
        </svg>
        &nbsp;
        <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '0.9rem' }}> {/* Slightly reduced font size */}
          {WebSitesettings.CompanyAddress}
        </span>
      </span>
    </div>
  </div>
)}
             
          <div className="carousel-wrapper">
            {isVisible && (
              <div className="carousel-container fade-in">
                <div id="gameCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" data-bs-touch="true">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src="https://i.postimg.cc/pX4fBDHm/Lowestcommission.jpg" className="d-block mx-auto carousel-img" alt="Lowerc" />
                    </div>
                    <div className="carousel-item">
                      <a href="https://whatsapp.com/channel/0029Var4q2bLY6d4AMR4h33z" target="_blank" rel="noopener noreferrer">
                        <img src="https://i.postimg.cc/TwVY5vcd/Whatsapp.jpg" className="d-block mx-auto carousel-img" alt="WhatsApp" />
                      </a>
                    </div>
                    <div className="carousel-item">
                      <a href="https://t.me/Ludo_pay" target="_blank" rel="noopener noreferrer">
                        <img src="https://i.postimg.cc/Wb9hTj3g/Telegram.jpg" className="d-block mx-auto carousel-img" alt="Telegram" />
                      </a>
                    </div>
                    <div className="carousel-item">
                      <a href="https://ludopay.in" target="_blank" rel="noopener noreferrer">
                        <img src="https://i.postimg.cc/3JNnVTny/App.jpg" className="d-block mx-auto carousel-img" alt="App" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
           
<div
  className="card mt-1 mb-1 p-2"
  style={{
    borderRadius: '5px',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#ffffff'
  }}
>
  <div className="d-flex align-items-center justify-content-between games-section-title">
    <h3 style={{ fontWeight: '600', color: '#333', margin: 0 }}>Our Games</h3>
<button
  className={`${styles.notificationButton} ${isButtonClicked ? styles.clicked : ""}`}
  onClick={handleShowCanvas}
  style={{
    backgroundColor: 'white',
    color: '#FF0000',
    padding: '6px 12px',
    borderRadius: '6px',
    border: '1px solid #FF0000',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    transition: 'background-color 0.3s ease, transform 0.2s',
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = '#f9f9f9';
    e.currentTarget.style.transform = 'scale(1.05)';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'white';
    e.currentTarget.style.transform = 'scale(1)';
  }}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    fill="#FF0000"
    className="me-1"
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
  </svg>
  &nbsp; How to Play
</button>
  </div>

  {/* Offcanvas */}
  {showCanvas && (
    <div className={styles.offcanvasBackdrop} onClick={handleCloseCanvas}>
      <div
        className={`${styles.offcanvas} ${isClosing ? styles.slideOut : styles.slideIn}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.offcanvasHeader}>
          <h5>How to Play</h5>
          <button
            className={styles.offcanvasClose}
            onClick={handleCloseCanvas}
          >
            ×
          </button>
        </div>
        <iframe
          src="https://www.youtube.com/embed/N4tUSsOih7M"
          title="How to Play"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )}
</div>
    <section className="games-section p-0">
      <div className="games-window">
        <Link className="gameCard-container" to={`/Homepage/Ludo%20Classics`} aria-label="Ludo Classics">
          <span className="blink live-label">◉ LIVE</span>
          <picture className="gameCard-image">
            <img width="100%" src="https://i.postimg.cc/cHbFR41n/Classic.jpg" alt="Ludo Classics" />
          </picture>
        </Link>
        <Link className="gameCard-container" to={`/Homepage/Ludo%20Classics`} aria-label="Ludo Classics">
          <span className="blink live-label">◉ LIVE</span>
          <picture className="gameCard-image">
            <img width="100%" src="https://i.postimg.cc/26GVMVL1/Classic-rich.jpg" alt="Ludo Classics" />
          </picture>
        </Link>
        <Link className="gameCard-container" to={``} onClick={() => toast.error("This game is coming soon!", { position: toast.POSITION.Bottom_Right })}>
          <div className="gameCard-image-container" style={{ position: "relative" }}>
<div className="overlay"></div>
            <img className="image" src="https://i.postimg.cc/qRq9y2jH/Lpludo.jpg" alt="" />
            <div className="coming-soon">
              <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMING SOON &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
            </div>
          </div>
        </Link>
        <Link className="gameCard-container" to={``} onClick={() => toast.error("This game is coming soon!", { position: toast.POSITION.Bottom_Right })}>
          <div className="gameCard-image-container" style={{ position: "relative" }}>
            <div className="overlay"></div>
            <img className="image" src="https://i.postimg.cc/mkFJ6Rvd/Tournament.jpg" alt="" />
            <div className="coming-soon">
              <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMING SOON &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
            </div>
          </div>
        </Link>
      </div>
    </section>
    <section className="footer" style={{ backgroundColor: "#f8f9fa", padding: "20px", borderTop: "1px solid #e0e0e0" }}>
      <a className="d-flex align-items-center px-3 py-3" href="#!" style={{ textDecoration: "none", color: "#007bff", padding: "10px 0", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }} onClick={() => setOpen(!open)} aria-controls="footer-collapse-text" aria-expanded={open}>
        <picture className="icon">
          <img src="https://i.postimg.cc/grb2XstT/Lplogo1.png" width="160px" height="40px" alt="ludopay Logo" style={{ width: "125px", height: "52px" }} />
        </picture>
        <span style={{ color: "#6c757d", fontSize: "1em", fontWeight: 400, marginLeft: "15px" }} className={!open ? "d-block" : "d-none"}>
          Help & Support
        </span>
        {open ? (
          <i className="mdi mdi-chevron-up ml-auto" style={{ fontSize: "1.7em", color: "#6c757d" }}></i>
        ) : (
          <i className="mdi mdi-chevron-down ml-auto" style={{ fontSize: "1.7em", color: "#6c757d" }}></i>
        )}
      </a>
      <Collapse in={open}>
        <div id="footer-collapse-text" className="px-3 overflow-hidden">
          <div className="row footer-links" style={{ borderTop: "1px solid #e0e0e0", paddingTop: "10px" }}>
            <div className="col-6 mb-3">
              <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                <Link to="/term-condition" style={{ color: "#007bff", textDecoration: "none" }}>Terms & Conditions</Link>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                <Link to="/PrivacyPolicy" style={{ color: "#007bff", textDecoration: "none" }}>Privacy Policy</Link>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                <Link to="/RefundPolicy" style={{ color: "#007bff", textDecoration: "none" }}>Refund/Cancellation Policy</Link>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                <Link to="/contact-us" style={{ color: "#007bff", textDecoration: "none" }}>Contact Us</Link>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                <Link to="/responsible-gaming" style={{ color: "#007bff", textDecoration: "none" }}>Responsible Gaming</Link>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                <Link to="/Rules" style={{ color: "#007bff", textDecoration: "none" }}>Game Rules</Link>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </section>
    <div className="downloadButton">
      <Downloadbutton />
    </div>
  </div>
  <ToastContainer style={{ marginBottom: '25px' }} position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
</div>
       
    </>
  );
}