import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import { Link } from 'react-router-dom';
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Gamehistory = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  const [user, setUser] = useState(null);
  const [roomCodes, setRoomCodes] = useState({});
  const [game, setGame] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage] = useState(50); // Number of games per page
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
      Allgames(res.data._id);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch user data.");
      setLoading(false);
    }
  };

  const fetchRoomCode = async (gameId) => {
    const access_token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const response = await axios.get(`${baseUrl}game/roomcode/get/${gameId}`, { headers });
      setRoomCodes((prev) => ({ ...prev, [gameId]: response.data.Room_code || 'N/A' }));
    } catch (error) {
      console.error(error);
    }
  };

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await axios.get(`${baseUrl}game/history/user/${id}`, { headers });
      const filteredGames = res.data.data.filter(ele => ['completed', 'cancelled', 'conflict'].includes(ele.Status));
      setGame(filteredGames);
      filteredGames.forEach(ele => fetchRoomCode(ele._id));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch game history.");
      setLoading(false);
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
  };

  // Pagination logic
  const indexOfLastGame = currentPage * gamesPerPage;
  const indexOfFirstGame = indexOfLastGame - gamesPerPage;
  const currentGames = game.slice(indexOfFirstGame, indexOfLastGame);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    role();
  }, []);

  return (
    <div>
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        <div className="pt-5 mb-3"></div>

        <div
          className="card mt-2 p-2"
          style={{
            backgroundColor: "#333333",
            borderRadius: "0px",
            padding: "10px",
            margin: "0 auto"
          }}
        >
          <div className="d-flex justify-content-between">
            <Link to="/Gamehistory" className={css.Linked}>
              <span className="font-9" style={{ fontWeight: '500', color: 'white' }}>Game</span>
            </Link>

            <Link to="/transaction-history" className={css.Link}>
              <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Wallet </span>
            </Link>

            <Link to="/Referral-history" className={css.Link}>
              <span className="font-9" style={{ fontWeight: '500', color: 'black' }}>Referral </span>
            </Link>
          </div>
        </div>

        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "80vh",
              backgroundColor: "#f9f9f9",
            }}
          >
            <div
              className="spinner-border"
              role="status"
              style={{
                width: "3rem",
                height: "3rem",
                borderWidth: "0.4rem", // Border ko bold karne ke liye
                borderColor: "blue", // Border ko blue color dene ke liye
                borderTopColor: "transparent", // Spinner ka effect maintain karne ke liye
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : error ? (
          <div
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h3 className="text-danger">{error}</h3>
            <p className="text-muted">Please try refreshing the page.</p>
          </div>
        ) : currentGames && currentGames.length > 0 ? (
          currentGames.map((card) => (
            <div
              key={card._id}
              className={`w-100 mt-2 py-1 d-flex align-items-center ${css.list_item}`}
              style={{
                backgroundColor: '#f5f5f5',
                borderRadius: '10px',
                border: '1px solid #ddd',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                color: '#333',
              }}
            >
              <div className={`mx-1 d-flex ${css.list_body}`}>
                <div className="d-flex align-items-center">
                  <picture className="mr-2">
                    <img
                      height="32px"
                      width="32px"
                      src="https://i.postimg.cc/cHbFR41n/Classic.jpg"
                      alt=""
                      style={{ borderRadius: "5px" }}
                    />
                  </picture>
                </div>

                <div className="d-flex flex-column">
                  <div style={{ fontSize: '14px', color: '#333', fontFamily: 'Arial, sans-serif', padding: '5px 0' }}>
                    {card.Status === "completed" ? (
                      card.Winner?._id === user?._id
                        ? <span style={{ fontWeight: 'bold', color: 'green' }}>Win Against</span>
                        : <span style={{ fontWeight: 'bold', color: 'red' }}>Lost Against</span>
                    ) : card.Status === "cancelled" ? (
                      <span style={{ fontWeight: 'bold', color: 'orange' }}>Cancelled</span>
                    ) : (
                      <span style={{ fontWeight: 'bold', color: 'darkblue' }}>{card.Status}</span>
                    )}
                    &nbsp;
                    <strong style={{ fontWeight: 'normal', color: '#555', fontSize: '0.775rem' }}>
                      {card.Accepetd_By?._id === user?._id
                        ? card.Created_by?.Name || 'N/A'
                        : card.Accepetd_By?.Name || 'N/A'}
                    </strong>
                  </div>
                  <div
                    className={`${css.games_section_headline}`}
                    style={{ fontSize: '10px', color: '#666', letterSpacing: '0.5px' }}
                  >
                    ROOM CODE: <span style={{ color: '#888', fontWeight: 'lighter' }}>{roomCodes[card._id] || 'N/A'}</span>
                  </div>
                  <div style={{ fontSize: '0.75rem', color: '#999' }}>
                    <div>{dateFormat(card.createdAt).split(',')[0]} {dateFormat(card.createdAt).split(',')[1]}</div>
                  </div>
                </div>
              </div>

              {card.Status === 'completed' && (
                <div style={{ marginLeft: 'auto' }} className="right-0 d-flex align-items-end pr-3 flex-column">
                  <div className="d-flex float-right font-8">
                    {card.Status === 'completed' && card.Winner?._id === user?._id && (
                      <>
                        <span className="text-success" style={{ fontSize: '0.8em', color: '#343a40' }}>
                          <picture className="ml-1 mb-1">
                            <img height="12px" width="12px" src="https://i.postimg.cc/rmFVG8B7/Plusicon.png" alt="" />
                          </picture>
                          <img
                            src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
                            width="16px"
                            alt=""
                            style={{ marginRight: '1px' }}
                          />
                          <b style={{ color: 'green' }}> {card.winnAmount || 'N/A'} </b>
                        </span>
                      </>
                    )}
                    {card.Status === 'completed' && card.Winner?._id !== user?._id && (
                      <>
                        <span className="text-danger" style={{ fontSize: '0.8em', color: '#343a40' }}>
                          <picture className="ml-1 mb-1">
                            <img height="12px" width="12px" src="https://i.postimg.cc/R0j1NZNQ/Minusicon.png" alt="" />
                          </picture>
                          <img
                            src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
                            width="16px"
                            alt=""
                            style={{ marginRight: '1px' }}
                          />
                          <b> {card.Game_Ammount || 'N/A'} </b>
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className={`${css.games_section_headline}`}
                    style={{
                      fontSize: '0.6rem',
                      whiteSpace: 'nowrap',
                      color: '#777',
                    }}
                  >
                    Closing balance: ₹{Math.floor(card.Winner?._id === user?._id ? card.Winner_closingbalance : card.Loser_closingbalance) || 0}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-center"
            style={{
              backgroundColor: '#f9f9f9',
              borderRadius: '10px',
              padding: '20px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
              color: '#333',
            }}>
            <img
              src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'}
              alt="no data"
              width={250}
              height={250}
              className="img-fluid"
              style={{ marginTop: "20%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold" style={{ color: '#333' }}>No Game History</h3>
              <p className="text-muted">You have not made any Gamehistory yet.</p>
            </div>
          </div>
        )}
<div role="group" className="btn-group" style={{
  marginTop: '15px',
  marginBottom: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //background: 'rgba(0, 123, 255, 0.7)', // More solid blue, reduced transparency
  borderRadius: '8px', // Reduced border-radius for a sharper look
  padding: '6px 10px',
  width: '100%',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)', // Light shadow for depth
  color: '#fff',
}}>
  &nbsp;
  <button
    type="button"
    disabled={currentPage === 1}
    className="btn btn-primary"
    style={{
  background: '#0056b3',
  color: 'white',
  border: 'none',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
  padding: '5px 8px',
  fontSize: '14px',
  cursor: 'pointer',
  fontWeight: 'bold',
  marginRight: '10px',
  transition: 'all 0.3s ease',
}}
    onClick={() => paginate(currentPage - 1)}
    onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
    onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
  >
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
      <b>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
        </svg>
      </b>
    </span>
  </button>
  &nbsp;
  {[currentPage - 1, currentPage, currentPage + 1].map(pageNumber => (
    pageNumber > 0 && pageNumber <= Math.ceil(game.length / gamesPerPage) ?
    <button
      key={pageNumber}
      type="button"
      className="btn btn-primary"
      style={{
  background: '#007bff',
  color: 'white',
  border: 'none',
  padding: '5px 8px',
  fontSize: '14px',
  cursor: 'pointer',
  fontWeight: 'bold',
  margin: '0 10px',
  transition: 'all 0.3s ease',
}}
      onClick={() => paginate(pageNumber)}
      onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
      onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
    >
      <b><span style={{ color: '#fff', fontWeight: 'bold' }}>{pageNumber}</span></b>
    </button> : null
  ))}
  &nbsp;
  <button
    type="button"
    disabled={currentPage * gamesPerPage >= game.length}
    className="btn btn-primary"
    style={{
      background: '#0056b3', // Darker blue for active button
      color: 'white',
      border: 'none', // No border
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
      padding: '5px 8px', // Smaller padding
      fontSize: '14px',
      cursor: 'pointer',
      fontWeight: 'bold',
      marginLeft: '10px', // Space after the last button
      transition: 'all 0.3s ease',
    }}
    onClick={() => paginate(currentPage + 1)}
    onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
    onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
  >
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
      <b>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
        </svg>
      </b>
    </span>
  </button>
  &nbsp;
</div>

      </div>

      <Rightcontainer />
    </div>
  );
};

export default Gamehistory;
