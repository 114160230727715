// import { width } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';


import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";


const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById('sidebarOverlay').style.display = 'none';

};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById('sidebarOverlay').style.display = 'block';
};

const Header = ({ user, loggedIn }) => {

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }


  const history = useHistory()

  let access_token = localStorage.getItem("token")
  access_token = localStorage.getItem("token")

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  }
  document.title=(WebSitesettings)?WebSitesettings.WebTitle:'LudoPay | Khelo Dil se Jeeto Dil se';
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
  },[])

const [walletBalance, setWalletBalance] = useState(user?.Wallet_balance || 0);
  const [referralWallet, setReferralWallet] = useState(user?.referral_wallet || 0);

  useEffect(() => {
    // Function to simulate fetching wallet data
    const fetchWalletData = () => {
      if (user) {
        setWalletBalance(user.Wallet_balance || 0);
        setReferralWallet(user.referral_wallet || 0);
      }
    };

    // Auto-refresh every second
    const interval = setInterval(fetchWalletData, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [user]);

  


  const logout = () => {

    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `logout`, {
      headers: headers
    }, { headers })
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token")
        window.location.reload()
        history.push('/')
      }).catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');

        }
      })

  }

  return (
    <div>

      {access_token ? (
<React.Fragment>
<div id='sidebarOverlay' onClick={w3_close}></div>
<div className="w3-sidebar w3-bar-block" id="mySidebar" style={{ paddingBottom: '70px' }}>

  <Link to={"/Profile"} className="w3-bar-item w3-button" onClick={w3_close}>
    <picture className="icon">
      {user && user.avatar ? (
        <img
          width="30px"
          height="30px"
          src={baseUrl + `${user.avatar}`}
          alt="profile"
          style={{ borderRadius: "50px" }} // Removed filter
        />
      ) : (
        <img
          src={baseUrl + WebSitesettings.SmallLogo}
          width="25px"
          height="25px"
          alt="profile"
          style={{}} // Removed filter
        />
      )}
    </picture>
    <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
      My Profile
    </div>
    <div className="divider"></div>
  </Link>


    <Link to={"/landing"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/gamepad.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        Win cash
      </div>
      <div className="divider"></div>
    </Link>

    <Link to={"/wallet"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/wallet.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        My wallet
      </div>
      <div className="divider"></div>
    </Link>

    <Link to={"/Gamehistory"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/order-history.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        History
      </div>
      <div className="divider"></div>
    </Link>
{/*
    <Link to="/transaction-history" className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/order-history.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        Transaction History
      </div>
      <div className="divider"></div>
    </Link>*/}

    <Link to={"/refer"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/sreferEarn.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        Refer and Earn
      </div>
      <div className="divider"></div>
    </Link>
{/*
    <Link to={"/Referral-history"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/refer-history.webp'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        Refer History
      </div>
      <div className="divider"></div>
    </Link>
    <Link to={"/Leaderboard"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/Leaderboard.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        Leaderboard
      </div>
      <div className="divider"></div>
    </Link>*/}
    <Link to={"/Notification"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/notifications.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        Notification
      </div>
      <div className="divider"></div>
    </Link>

    <Link to={"/support"} className="w3-bar-item w3-button" onClick={w3_close}>
      <picture className="icon">
        <img alt="img" src={process.env.PUBLIC_URL + '/Images/Header/support.png'} style={{ filter: "grayscale(100%)" }} />
      </picture>
      <div style={{ marginLeft: '.5rem', fontSize: '0.95rem', fontWeight: 'bold' }}>
        Support
      </div>
      <div className="divider"></div>
    </Link>

  </div>


          <div className="w3-teal">
            <div className="w3-container ">
              <div className={`${css.headerContainer} `}>
                <button
                  className="w3-button w3-teal w3-xlarge float-left"
                  onClick={w3_open} id="hambergar"
                >
                  <picture className={`${css.sideNavIcon} mr-0`}>
                    <img
                      src='/Images/LandingPage_img/sidebar.png'
                      className="snip-img"
                      alt=''
                    />
                  </picture>
                </button>
                <Link to="/" >
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                  <img
                  src="https://i.postimg.cc/GmcHMVJt/Ludopay.png"
                  className="snip-img"
                  alt='Logo'
                />
                  </picture>
                </Link>
                <div >
                  <div className={`${css.menu_items}`}>
                    <Link className={`${css.box}`} to="/Addcase">
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
                          className="snip-img"
                          alt=''
                        />
                      </picture>


    
      <div className="mt-1 ml-1">
        <div className={`${css.moneyBox_header}`}>Cash</div>
        <div className={`${css.moneyBox_text}`}>
          {user && user.Wallet_balance !== undefined 
            ? Math.floor(user.Wallet_balance) 
            : 0}
        </div>
      </div>
      <picture className={`${css.moneyBox_add}`}>
        <img
          src="https://i.postimg.cc/wjGQLz9r/global-add-Sign.png"
          className="snip-img"
          alt=""
        />
      </picture>
    </Link>
{user && user.referral_wallet !== undefined && user.referral_wallet >= 1 ? (
  <Link
    className={`${css.box} ml-2`}
    to="/redeem/refer"
    style={{ width: `${50 + (Math.floor(user.referral_wallet).toString().length * 10)}px` }}
  >
    <picture className={`${css.moneyIcon_container}`}>
      <img
        src={process.env.PUBLIC_URL + '/Images/LandingPage_img/notification-activity-reward.png'}
        className="snip-img"
        alt=''
      />
    </picture>
    <div className="mt-1 ml-1">
      <div className={`${css.moneyBox_header}`}>Earning</div>
      <div className={`${css.moneyBox_text}`}>
        ₹{Math.floor(user.referral_wallet)}
      </div>
    </div>
  </Link>
) : null}
</div> 
                  <span className="mx-5"></span>
                </div>
                <span className="mx-5"></span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="w3-teal " >
          <div className="w3-container ">
            <div className={`${css.headerContainer} justify-content-between`}>

              <Link to="/" >
                <picture className={`ml-2 ${css.navLogo} d-flex`}>
                <img
                  src="https://i.postimg.cc/GmcHMVJt/Ludopay.png"
                  className="snip-img"
                  alt='Logo'
                />
                </picture>
              </Link>

              <div className={`ml-5`}>
                <Link
                  type="button"
                  className="login-btn border-success text-success"
                  to="/signup"
                >
                  SIGNUP
                </Link>
                <Link type="button" className="login-btn" to="/login">
                  LOGIN
                </Link>
              
              </div>
            </div>
          </div>
        </div>
      )
      }

    </div>
  );
};

export default Header; 