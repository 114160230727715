import React, { useEffect, useState, useRef } from 'react'
import '../css/viewGame1.css'
import "../css/layout.css"
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../Components/Header';
import css from '../css/Pan.module.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Loader.css';
import { NavLink } from 'react-router-dom';
//import NoticeSound from "./Notice.mp3";

export default function ViewGame1(props) {
  const history = useHistory()
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode == "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [Game, setGame] = useState()
  const [status, setStatus] = useState(null);
  const [fecthStatus, setFecthStatus] = useState()
  const [scrnshot, setScrnshot] = useState(null)
  const [scrnshot1, setScrnshot1] = useState("")
   
  const [reason, setReason] = useState(null)
  const [socket, setSocket] = useState();
  const [roomcode, setRoomcode] = useState('')
  let submitReq=useRef(false);
  const isMounted= useRef(true);

  const [submitProcess, setProcess] = useState(true);

  const getPost = async () => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.patch(baseUrl+`challange/roomcode/${path}`,
      {
        Room_code: roomcode
      }
      , { headers })
      .then((res) => {
        setGame(res.data)
        toast.success("Roomcode updated", { position: "bottom-right" });
        socket.emit('challengeOngoing');
        
      })
      .catch(e => {
          const status = e.response?.status;
          
       if (status === 401) {
    // Handle unauthorized error
    localStorage.removeItem('token');
    window.location.reload();
    history.push("/login");
  } else if (status === 400) {
    // Handle bad request error
    toast.error(e.response.data.details, { position: "bottom-right" });
  } else {
    // Handle other errors (optional)
    console.error("An unexpected error occurred:", e);
    toast.error("Battle has been cancelled, not found", { position: "bottom-right" });
  } 
      })
  }
  const [copyNotice, setCopyNotice] = useState(false);
  const [ludoNotice, setLudoNotice] = useState(false);

  const copyCode = () => {
    navigator.clipboard.writeText(Game.Room_code);
    toast.success("Room code copied to clipboard!", { position: "bottom-right" });
    setCopyNotice(true);
    setTimeout(() => setCopyNotice(false), 2000);
  };

  const openLudoKing = () => {
    window.open("https://lk.gggred.com/?rmc=&gt=0", "_blank");
    toast.info("Ludo King link opened!", { position: "bottom-right" });
    setLudoNotice(true);
    setTimeout(() => setLudoNotice(false), 2000);
  };
// user details start
const [user, setUser] = useState();
const [userAllData, setUserAllData] = useState();

const role = async () => {
  const access_token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${access_token}`
  };

  await axios.get(baseUrl+`me`, { headers })
    .then((res) => {
      setUser(res.data._id);
      setUserAllData(res.data);
      Allgames(res.data._id);
      getCode(res.data._id);
    })
    .catch(e => {
      if (e.response.status == 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('token');
         window.location.reload(); 
        history.push("/login");
      }
    });
};

     const [showNotice, setShowNotice] = useState(false);

  const handleInputChange = (e) => {
    const inputVal = e.target.value;

    if (inputVal === '' || inputVal.startsWith('0')) {
      const validCode = inputVal.replace(/[^0-9]/g, '').slice(0, 8);
      setRoomcode(validCode);
      setShowNotice(false); // Hide notice if the input is valid
    } else {
      setShowNotice(true); // Show notice if the input is invalid
    }
  };
    


const [isCardOpen, setIsCardOpen] = useState(true); // Start with the card open
const [selectedReason, setSelectedReason] = useState("");

const reasons = [
  "No Room Code",
  "Game Not Started",
  "User Not Joined",
  "User Not Playing",
];

const handleReasonSelect = (reason) => {
  setSelectedReason(reason); // Select the reason
  setIsCardOpen(false); // Close the card after selecting the reason
};

const handleSubmit = () => {
  if (!selectedReason) {
    alert("Please select a reason before submitting!");
    return;
  }

  // Add your submit logic here
  console.log("Reason submitted:", selectedReason);
};

// user details end
  const [ALL, setALL] = useState()

    const Allgames = async (userId) => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    await axios.get(baseUrl+`challange/${path}`, { headers })
      .then((res) => {
           if(res.data.Status=="new"||res.data.Status=="requested")
        {
          setTimeout(async() => {
            await axios.get(baseUrl+`challange/${path}`, { headers })
            .then((res) => {
              if(res.data.Status=="new"||res.data.Status=="requested")
              {
                history.push(props.location.state.prevPath);
              }
              else{
                setProcess(false);
              }
            })
            .catch((error)=>{
              console.error(error);
              history.push(props.location.state.prevPath);
            })
          }, 10000);
        }
         else{
          setProcess(false)
        }
        setALL(res.data)
        setGame(res.data)
        if (userId == res.data.Accepetd_By._id)
          setFecthStatus(res.data.Acceptor_status)

        if (userId == res.data.Created_by._id)
          setFecthStatus(res.data.Creator_Status)

       
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          history.push("/login")
        }
      })
  }

const getCode = async (userId) => {
  const access_token = localStorage.getItem('token')
  const headers = {
    Authorization: `Bearer ${access_token}`
  }
  
  let intervalId; // Declare intervalId variable

  // Define a function to fetch data and update UI
  const fetchDataAndUpdateUI = async () => {
    const res = await axios.get(baseUrl + `game/roomcode/get/${path}`, { headers });
    // setALL(res.data)
    Allgames(userId)
    if (res.data.Accepetd_By == userId && res.data.Room_code == 0) {
     
    } else {
      
      clearInterval(intervalId);
    }
  };

  
  await fetchDataAndUpdateUI();

  intervalId = setInterval(fetchDataAndUpdateUI, 1000); 
}
  const checkExpire = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    await axios.get(baseUrl+`game/roomcode/expire/${path}`, { headers })
      .then((res) => {
      
        history.goBack();
      })
  } 
  
  
  useEffect(() => {
  WebSocket.prototype.emit= function (event,data) { 
    if(this.readyState===WebSocket.OPEN)
      this.send(JSON.stringify({event,data}))
  }
  WebSocket.prototype.listen= function (eventName,callback) {  
    this._socketListeners = this._socketListeners ||  {}
    this._socketListeners[eventName] = callback
  }

  let socket = new WebSocket("wss://socket.hiplays.in/server");

  function openFunc() {
    socket.onopen = () => {
      console.log('websocket is connected 馃憤');
      setSocket(socket);
      socket.pingTimeout = setTimeout(() => {
        socket.close();
        setSocket(undefined);
      }, 30000 + 1000);
    };
  }

  function listenFunc() {
    const checkExpire = async () => {
      const access_token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${access_token}`
      };

      try {
        await axios.get(baseUrl + `game/roomcode/expire/${path}`, { headers });
        history.goBack();
      } catch (error) {
        console.error('Error checking expiration:', error);
      }
    }
  }

  function closeFunc() {
    socket.onclose = () => {
      console.log('socket disconnected wow 馃槨');
      if (isMounted.current) {
        clearTimeout(socket.pingTimeout);
        setSocket(undefined);
        socket = new WebSocket("wss://socket.hiplays.in/server");  // Initialize here
        openFunc();
        listenFunc();
        closeFunc();
      }
    };
  }

  openFunc();
  listenFunc();
  closeFunc();

  return ()=>{
    isMounted.current=false;
    clearTimeout(socket.pingTimeout);
    setSocket(undefined);
    socket.close();
  }
}, [])

  useEffect(() => {
    let access_token = localStorage.getItem('token');
    access_token = localStorage.getItem('token');
    if (!access_token) {
      window.location.reload()
      history.push("/login");
    }
    // console.log(history.location)

    role();
   

  }, [])


  const clearImage = (e) =>{
    setScrnshot1(null)
    setScrnshot(null)
    setStatus(null)
  }
  
 
  // Result
  const Result =async (e) => {
    e.preventDefault();
    if(submitReq.current==false)
    {
      submitReq.current=true;
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    if (status) {       
    setProcess(true);
      const formData = new FormData();
      formData.append('file', scrnshot);
      formData.append('status', status);
      if (status == 'cancelled') {
        formData.append('reason', reason);
      }
  
      await axios({
        method: "post",
        url: baseUrl+`challange/result/${path}`,
        data: formData,
        headers: headers,
      }).then((res) => {
          // socket.emit('resultAPI');
          submitReq.current=false;
          setProcess(false);
          history.push(props.location.state.prevPath);
      })
        .catch((e) => {
          console.log(e)
          if (e.response?.status == 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('token');
            window.location.reload()
            history.push("/login")
          }
        })
    }
  submitReq.current = false;
toast.error('battle review is in progress. Please wait');
   
  }
    
  


  }
  const Completionist = () => <span>You are good to go!</span>;

 // ADDED BY TEAM
  const handleChange = (e) =>{
    setScrnshot1(URL.createObjectURL(e.target.files[0]))
    setScrnshot(e.target.files[0])
  }
  
  const handleClick = () => {
    setStatus("winn");
  }
 const [toastShown, setToastShown] = useState(false);


    useEffect(() => {
        if (Game && Game.roomCodeStatus) {
            if (!toastShown) {
                if (Game.roomCodeStatus === 'Room code found and updated') {
                    toast.success("Classic room code updated successfully!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                    });
                } else {
                    toast.error("Invalid room code or not a classic room code.", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                    });
                }
                setToastShown(true); // Set the flag to true after showing the toast
            }
        } else {
            console.error("Game or roomCodeStatus is undefined");
        }
    }, [Game, toastShown]); 

  return (
    <>
    <Header user={userAllData} />
       {Game && <div className='leftContainer'>
        <div className='main-area' style={{ paddingTop: '60px' }}>
<div className="card mb-2 p-2" style={{ borderRadius: '8px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', backgroundColor: '#fff' }}>
  <div className="d-flex justify-content-between align-items-center">
    <button onClick={() => history.goBack()} className="btn btn-dark" style={{ backgroundColor: '#000', borderRadius: '5px' }}>
      <i className="fa fa-arrow-circle-left" style={{ color: '#fff' }}></i>
      <span className="text-capitalize fw-bold" style={{ color: '#fff' }}><b>BACK</b></span>
    </button>

    <NavLink to="/Rules" style={{ marginLeft: 'auto', textDecoration: 'none', color: '#000', display: 'flex', alignItems: 'center', backgroundColor: '#fff', padding: '0.2rem 0.4rem', borderRadius: '5px', fontSize: '0.8rem', textTransform: 'uppercase', border: '1px solid #000' }}>
      <span className="fw-bold" style={{ color: '#000', fontFamily: 'roboto', display: 'flex', alignItems: 'center' }}>
        <b>RULES</b>&nbsp;
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>
      </span>
    </NavLink>
  </div>
</div>
{/*<audio src={NoticeSound} autoPlay onEnded={(e) => e.target.removeAttribute('autoplay')}>
</audio>*/}
         {!Boolean(submitProcess) && 
       <div>
<div className="battleCard" style={{ border: '0px solid #ddd', borderRadius: '8px', padding: '2px' }}>
  <div className="card mt-0" style={{ border: '1px solid rgb(128, 128, 128)', width: '98%', margin: '0 auto', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', padding: '5px' }}>
    <div className="card-header text-center" style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.9px', padding: '2px 6px', textAlign: 'center', background: '#E8ECEF', display: 'flex', justifyContent: 'space-between' }}>
      <p style={{ margin: '2px 0', fontSize: '14px', fontWeight: '700', color: 'green', letterSpacing: '1px', textShadow: '1px 1px 2px rgba(0,0,0,0.2)', fontFamily: 'Arial, sans-serif', display: 'inline' }}>
        {Game.Created_by && Game.Created_by.Name}
      </p>
      <span style={{ margin: '2px 0', fontSize: '14px', fontWeight: '700', color: 'green', letterSpacing: '1px', textShadow: '1px 1px 2px rgba(0,0,0,0.2)', fontFamily: 'Arial, sans-serif', display: 'inline' }}>
        {Game.Accepetd_By && Game.Accepetd_By.Name}
      </span>
    </div>

 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <img
        src={Game.Created_by && Game.Created_by.avatar ? baseUrl + `${Game.Created_by.avatar}` : "https://Ludopay.in/user1.png"}
        onError={(e) => { e.target.onerror = null; e.target.src = "https://ludopay.in/user1.png" }}
        width='45px' height='45px' alt=''
        style={{ borderRadius: '50%', margin: '5px 5px 5px 0' }}
    />
    <img
        src="https://i.postimg.cc/kG2gYsfM/vs.png"
        width='25px'
        alt=''
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
    />
    <img
        src={Game.Accepetd_By && Game.Accepetd_By.avatar ? baseUrl + `${Game.Accepetd_By.avatar}` : "https://ludopay.in/user.png"}
        onError={(e) => { e.target.onerror = null; e.target.src = "https://ludopay.in/user.png" }}
        width='45px' height='45px' alt=''
        style={{ borderRadius: '50%', margin: '5px 0 5px 5px' }}
    />
</div>

<div style={{ backgroundColor: '#F7F7F7', padding: '5px' }}>
    <div className='amount mt-1 text-center'>
        <span style={{ opacity: '0.8' }}>Playing for</span>
        <img
            className='mx-1'
            src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp"
            width='20px'
            alt=''
        />
        <span style={{ fontSize: '1em', fontWeight: 700, opacity: '0.8' }}>{Game.Game_Ammount}</span>
    </div>
</div>
</div>
 
 
    { Game.Room_code == null && 

<div className='roomCode cxy flex-column'>
  Waiting for Room Code...
  
  <div className="loaderReturn custom-spinner">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </div>
</div>  
                    
              || Game.Room_code != 0 &&
                <div className='roomCode cxy flex-column'>

                  <div className='text-center'>
                    <div>Classic Ludoking Roomcode</div>
<h2 style={{ color: '#3D6D3C', fontWeight: '700', marginRight: '15px', flex: 1, fontSize: '24px', letterSpacing: '1px', textTransform: 'uppercase', textAlign: 'center', padding: '10px', borderRadius: '8px', fontFamily: 'Poppins, sans-serif' }}>{Game.Room_code}</h2>
                  </div> 
<div className="d-flex justify-content-between align-items-center">
  <div style={{ textAlign: "center", margin: "10px" }}>
    <button 
      className="btn btn-primary" 
      style={{
        width: "150px", 
        borderRadius: "5px", 
        color: "white", 
        backgroundColor: "#0D6EFD", 
        fontWeight: "bold", 
        boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)", 
        padding: "8px 16px", 
        fontSize: "0.9em", 
        cursor: "pointer", 
        position: "relative"
      }} 
      onClick={copyCode}>
      <span style={{ color: '#fff', fontWeight: 'bold' }}>
        <b><i className="fa fa-copy" style={{ marginRight: "5px" }}></i> Copy Room Code</b>
      </span>
      {copyNotice && (
        <div 
          style={{
            position: "absolute", 
            top: "100%", 
            left: "50%", 
            transform: "translateX(-50%)", 
            marginTop: "5px", 
            padding: "3px 10px", 
            background: "#D4EDDA", 
            color: "#155724", 
            borderRadius: "5px", 
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)", 
            fontSize: "0.75em",
            fontWeight: "bold", 
            animation: "slideIn 0.5s"
          }}>
          Room Code Copied!
        </div>
      )}
    </button>
  </div>

  <div style={{ textAlign: "center", margin: "10px" }}>
    <button 
      className="btn btn-success" 
      style={{
        width: "150px", 
        borderRadius: "5px", 
        color: "white", 
        backgroundColor: "#28A745", 
        fontWeight: "bold", 
        boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)", 
        padding: "8px 16px", 
        fontSize: "0.9em", 
        cursor: "pointer", 
        position: "relative"
      }} 
      onClick={openLudoKing}>
      <span style={{ color: '#fff', fontWeight: 'bold' }}>
        <b><i className="fa fa-gamepad" style={{ marginRight: "5px" }}></i> Open Ludo King</b>
      </span>
      {ludoNotice && (
        <div 
          style={{
            position: "absolute", 
            top: "100%", 
            left: "50%", 
            transform: "translateX(-50%)", 
            marginTop: "5px", 
            padding: "3px 10px", 
            background: "#FFF3CD", 
            color: "#856404", 
            borderRadius: "5px", 
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)", 
            fontSize: "0.75em",
            fontWeight: "bold", 
            animation: "slideIn 0.5s"
          }}>
          Opening Ludo King...
        </div>
      )}
    </button>
  </div>
</div>
</div>
                 
                || Game.Room_code == 0 && (Game.Created_by._id == user && <div className='roomCode cxy flex-column'>
              
  {roomcode.length !== 8 && <strong style={{ color: 'red', fontSize: '10px' }}>
  यदि रूम कोड सेट नहीं हो रहा है, तो 2-3 बार सेट करने की कोशिश करें, रूम कोड सेट हो जाएगा। यहां सिर्फ क्लासिक रूम कोड डाला जा सकता है।
</strong>}
       <div style={{ maxWidth: '300px', margin: '20px auto' }}>
       
      <input
        type="text"
        className="cool-input mt-0 w-10"
        style={{
          backgroundColor: '#f4f4f4',
          border: '1px solid #0FFF50',
          borderRadius: '4px',
          height: '35px',
          boxShadow: '0 0 5px rgba(0, 255, 80, 0.5)',
          width: '100%',
        }}
        value={roomcode}
        onChange={handleInputChange}
        placeholder="Enter Room Code"
        title="Enter Room Code"
      />
      {showNotice && (
        <div
          style={{
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#FFD2D2',
            border: '1px solid #FF0000',
            borderRadius: '4px',
            color: '#FF0000',
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          Enter only Ludoking Classic roomcode. 
        </div>
      )}
    </div>
<button
  className="btn btn-custom"
  style={{
    width: "180px",
    height: "40px",
    backgroundColor: "#3B82F6", // Improved blue shade
    borderRadius: "6px",
    color: "white", // White text for contrast
    border: "none",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)",
    fontSize: "1em",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: isLoading ? "not-allowed" : "pointer",
    position: "relative",
    transition: "background-color 0.3s ease",
  }}
  disabled={isLoading}
  onClick={(e) => {
    e.preventDefault();
    if (roomcode.length === 8) {
      setIsLoading(true);
      getPost().finally(() => {
        setIsLoading(false);
      });
    } else {
      toast.error("Enter a Room Code.");
    }
  }}
>
  {isLoading ? (
    <>
      <i
        className="fa fa-circle-notch fa-spin"
        style={{
          color: "white",
          marginRight: "8px",
          fontSize: "1.2em",
        }}
      ></i>
      <span style={{ color: 'white', fontWeight: 'bold' }}>
                    <b>Checking...</b></span>
    </>
  ) : (
    <span style={{ color: '#fff', fontWeight: 'bold' }}>
                    <b>SET ROOMCODE</b></span>
  )}
</button>

                </div> || (Game.Accepetd_By._id == user) &&
<div className='roomCode cxy flex-column'>
  <span className='waitingText'>Waiting for Room Code</span>
  <div className='spinner'></div>
  <br />
  <strong style={{ color: 'red', fontSize: '10px' }}>
  कृपया इंतजार करें, रूम कोड आने में एक मिनट तक का समय लग सकता है।
</strong>
</div>)
              }

      
<div className="card mt-3" style={{ border: '1px solid rgba(128, 128, 128, 0.5)', borderRadius: '4px', width: '96%', margin: '0 auto', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', backgroundColor: '#ffffff' }}>
  <div className="card-header text-center" style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.9px', backgroundColor: '#E8ECEF', padding: '8px 16px', textAlign: 'center', color: '#000' }}>Match Status</div>
    <div className="card mb-2 p-2" style={{ borderRadius: '8px', boxShadow: '0px 0px 5px rgba(128, 128, 128)' }}>
              <form className='result-area' onSubmit={(e) => { Result(e) }} encType="multipart/form-data">

                {fecthStatus !== null && fecthStatus !== undefined && 
                        <div className="card" style={{ backgroundColor: 'lightgrey' }}>
      <p>You have already updated your battle result for <h6 className='d-inline-block text-uppercase' style={{ color: 'red', fontWeight: 'bold' }}>{fecthStatus}</h6></p>
      "Awaiting admin confirmation"</div>}
                {fecthStatus == null && <><div className="card" style={{ backgroundColor: 'light' }}>
       <p>
                  After completion of your game, select the status of the game
                  and post your screenshot below.
                </p></div><br />
                   <div
                    className='MuiFormGroup-root radios'
                    role='radiogroup'
                    aria-label='Result'
                  >           
<label className='MuiFormControlLabel-root' style={{ position: 'relative' }}>
  <div
    className='custom-button new-button-style'
    onClick={handleClick}
    style={{
      backgroundColor: status === "winn" ? 'green' : 'initial',
      color: status === "winn" ? 'white' : 'green',
      width: '100%'  // Added width for full width button
    }}
  >
    I Win
  </div>
</label>

<label className='MuiFormControlLabel-root' style={{ position: 'relative' }}>
  <div
    className='custom-button new2-button-style'
    onClick={() => setStatus("lose")}
    style={{ backgroundColor: status === "lose" ? 'red' : 'initial', color: status === "lose" ? 'white' : 'red', border: '1px solid red', borderRadius: '5px', cursor: 'pointer', padding: '10px', position: 'relative' }}
  >
    I Lost
  </div>
</label>

<label className='MuiFormControlLabel-root' style={{ position: 'relative' }}>
  <div
    className='custom-button new3-button-style'
    onClick={() => setStatus("cancelled")}
    style={{ backgroundColor: status === "cancelled" ? 'grey' : 'initial', color: status === "cancelled" ? 'white' : 'grey', border: '1px solid grey', borderRadius: '5px', cursor: 'pointer', padding: '10px', position: 'relative' }}
  >
    Cancel
  </div>
</label>
</div></>  }


                {status !== null && status !== 'cancelled' && status !== 'lose' && <div className={`${css.doc_upload} mt-5`} >
 
<input type="file" onChange={handleChange} accept="image/*" required />
{!scrnshot ? (
 <div className="cxy flex-column position-absolute ">
    <img
      className="upload-icon mx-1"
      src="https://i.postimg.cc/CL5npZ5J/uploadicon.png"
      width="25px"
      alt="Upload Icon"
    />
    <div className="upload-text mt-1">
      Upload winning screenshot.
    </div>
  </div>
) : (
  <div className="uploaded-container d-flex align-items-center">
    <img
      src="https://i.postimg.cc/tg1vQkx2/Filesicon.png"
      width="26px"
      alt="File Icon"
      className="file-icon mr-2"
    />
    <div className="file-details d-flex flex-column" style={{ width: '80%' }}>
      <div className="file-name">
        {scrnshot.name}
      </div>
      <div className="file-size">
        {(scrnshot.size / 1024 / 1024).toFixed(2)} MB
      </div>
    </div>
    <div className="cancel-icon" onClick={clearImage} style={{ cursor: 'pointer' }}>
      <img
        src="https://i.postimg.cc/3NyGBPQ2/free-cancel-207-433817.png"
        width="23px"
        alt="Cancel Icon"
      />
    </div>
  </div>
)}
                </div>}
  {status !== null && status === 'cancelled' && (
  <div className="reason-selector">
    <div
      className="reason-card-toggle"
      onClick={() => setIsCardOpen(!isCardOpen)}
    >
      {selectedReason ? selectedReason : "Select a Cancel Reason"}
    </div>

    {isCardOpen && (
      <div className="reason-card">
        {reasons.map((reason, index) => (
          <div
            key={index}
            className="reason-option"
            onClick={() => {
              // Only update the selected reason if the reason is valid
              if (reason) {
                handleReasonSelect(reason);
              }
            }}
          >
            {reason}
          </div>
        ))}
      </div>
    )}
  </div>
)}
     
          <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "10px", marginBottom: "10px" }}>
  <img src={scrnshot1} style={{ width: "60%" }} />
</div>   
                {fecthStatus == null && fecthStatus == undefined && <input type="submit" class="custom-btn" id="post" value="Post Result" />
}
              </form>
            </div>
          </div>
          </div>
        </div>
                }
                </div>
  </div>}
  
         <div className="card mt-3" style={{ border: '1px solid rgba(128, 128, 128, 0.5)', borderRadius: '4px', width: '96%', margin: '0 auto', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', backgroundColor: '#ffffff' }}>
  <div className="card-header text-center" style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: '0.9px', backgroundColor: '#E8ECEF', padding: '8px 16px', textAlign: 'center', color: '#000' }}>Importent Rools</div>
      <ul style={{ fontSize: '0.9rem', margin: '10px 0', paddingInlineStart: '20px' }}>
        <li>
          Record every game while playing.
        </li>
        
        <li>
          For cancellation of the game, video proof is necessary.
        </li>
        
        <li style={{ backgroundColor: '#fff', borderRadius: '5px', marginTop: '10px', padding: '5px' }}>
          <img src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp" width='18px' alt='' /> 
          <span style={{ color: '#ff0000', fontSize: '0.9rem' }}><b>50 Penalty</b>&nbsp;</span> for updating the wrong result.
        </li>
        
        <li style={{ backgroundColor: '#fff', borderRadius: '5px', marginTop: '10px', padding: '1px' }}>
          <img src="https://i.postimg.cc/XJXR7Q1S/global-rupee-Icon.webp" width='18px' alt='' /> 
          <span style={{ color: '#ffcc00', fontSize: '0.9rem' }}><b>25 Penalty</b>&nbsp;</span> for not updating the result.
        </li>
      </ul>
    </div>
    
  
  
       {Boolean(submitProcess) &&
<div className="loaderReturn">
  <p className="loaderText">Updating...Please Wait...</p>

  <div className="progressBarContainer">
    <div className="progressBox"></div>
    <div className="progressBox"></div>
    <div className="progressBox"></div>
    <div className="progressBox"></div>
    <div className="progressBox"></div>
  </div>
</div>
       }<br />
   <ToastContainer
  style={{ marginBottom: '25px' }}
  position="bottom-center"
  autoClose={3000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="light"
/>   
    </>
  )
}
